import Utils from '@/lin/util/util'
import homeConfig from './home' //引入模块
import errorConfig from './error' //引入模块
import personalConfig from './personal' //引入模块
// import adminConfig from './admin' //引入模块

let homeRouter = [homeConfig, personalConfig, errorConfig]
if (window.localStorage.getItem('tokensss')) {
  homeRouter.push(...JSON.parse(window.localStorage.getItem('tokensss')))
}
// if (window.localStorage.getItem('tokensss') == undefined) {
//   homeRouter.push(homeConfig, personalConfig, errorConfig)
// } else {
//   homeRouter = [homeConfig, personalConfig, errorConfig, ...JSON.parse(window.localStorage.getItem('tokensss'))]
// }

// 接入插件
// const plugins = [...pluginsConfig]
// filterPlugin(menuTree.homeRouter)
// homeRouter = homeRouter.concat(plugins)
window.onload = function () {
  //code
}
homeRouter = Utils.sortByOrder(homeRouter)
deepReduceName(homeRouter)
// 处理顺序
export default homeRouter

/**
 * 筛除已经被添加的插件
 */
// function filterPlugin(data) {
//   if (plugins.length === 0) {
//     return
//   }
//   if (Array.isArray(data)) {
//     data.forEach(item => {
//       filterPlugin(item)
//     })
//   } else {
//     const findResult = plugins.findIndex(item => data === item)
//     if (findResult >= 0) {
//       plugins.splice(findResult, 1)
//     }
//     if (data.children) {
//       filterPlugin(data.children)
//     }
//   }
// }

/**
 * 使用 Symbol 处理 name 字段, 保证唯一性
 */
function deepReduceName(target) {
  if (Array.isArray(target)) {
    target.forEach(item => {
      if (typeof item !== 'object') {
        return
      }
      deepReduceName(item)
    })
    return
  }
  if (typeof target === 'object') {
    if (typeof target.name !== 'symbol') {
      target.name = target.name || Utils.getRandomStr()
      target.name = Symbol(target.name)
    }

    if (Array.isArray(target.children)) {
      target.children.forEach(item => {
        if (typeof item !== 'object') {
          return
        }
        deepReduceName(item)
      })
    }
  }
}
