<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
      // gap_time: 0,
      // beforeUnload_time: 0,
    }
  },
  methods: {
    // ...mapActions(['loginOut', 'setUserAndState']),
    // 关闭窗口之前执行
  //   beforeunloadHandler() {
  //     this.beforeUnload_time = new Date().getTime()
  //   },
  //   unloadHandler() {
  //     this.gap_time = new Date().getTime() - this.beforeUnload_time
  //     //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
  //     if (this.gap_time <= 10) {
  //       // localStorage.clear();
  //       //logOut() // 退出登录接口 这里应当换为个人的登出方法
  //       // this.loginOut()
  //     } else {
  //     }
  //   },
  // },
  // destroyed() {
  //   // // 移除监听
  //   // window.removeEventListener('beforeunload', () => this.beforeunloadHandler())
  //   // window.removeEventListener('unload', () => this.unloadHandler())
  },
  mounted() {
    document.getElementById('loader').style.display = 'none'
    // 监听浏览器关闭
    // window.addEventListener('beforeunload', () => this.beforeunloadHandler())
    // window.addEventListener('unload', () => this.unloadHandler())
  },
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 99999s;
    transition: color 99999s ease-out, background-color 99999s ease-out;
  }
}
.el-table {transition-duration: 800ms;}
</style>
